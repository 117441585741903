import React, { ReactNode, useState, useEffect } from "react"

import { Notifier } from "@airbrake/browser"
import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary as useReactErrorBoundary,
} from "react-error-boundary"
import { useTranslation } from "react-i18next"

import { logger } from "@helpers/logger"

import newrelic from "../../newrelic"

// Replace with your actual Airbrake project and API key
const AIRBRAKE_PROJECT_ID = 514656
const AIRBRAKE_API_KEY = import.meta.env.REACT_APP_AIRBRAKE_API_KEY || ""
const isDevMode =
  import.meta.env.REACT_APP_ENV === "development" ||
  import.meta.env.REACT_APP_ENV === "test"

interface FallbackProps {
  error: Error
  resetErrorBoundary: (...args: Array<unknown>) => void
}

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation()

  return (
    <div role="alert">
      <p>{t("errorBoundary_fallbackTitle")}:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetErrorBoundary} type="button">
        {t("errorBoundary_fallbackText")}
      </button>
    </div>
  )
}

interface ErrorBoundaryProps {
  children: ReactNode
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
}: ErrorBoundaryProps) => {
  const [airbrake, setAirbrake] = useState<Notifier | null>(null)

  useEffect(() => {
    // Initialize the Airbrake instance
    let airbrakeInstance: Notifier | null = null

    if (!isDevMode && AIRBRAKE_API_KEY) {
      airbrakeInstance = new Notifier({
        projectId: AIRBRAKE_PROJECT_ID,
        projectKey: AIRBRAKE_API_KEY,
        environment: import.meta.env.REACT_APP_ENV,
      })

      setAirbrake(airbrakeInstance)
    }

    // Cleanup the Airbrake instance on unmount
    return () => {
      if (airbrakeInstance) {
        airbrakeInstance.close()
      }
    }
  }, [])

  const logError = (
    error: Error,
    info: { componentStack: string | null | undefined },
  ) => {
    newrelic?.noticeError(error, {
      params: info.componentStack || "Component stack unavailable",
    })
    if (airbrake && !isDevMode) {
      // Notify Airbrake about the error
      airbrake.notify({
        error,
        params: info.componentStack || "Component stack unavailable",
      })
    }
  }

  return (
    <ReactErrorBoundary
      FallbackComponent={Fallback}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
        logger.error(details)
      }}
      onError={(error, info) =>
        logError(error, info as { componentStack: string | null | undefined })
      }
    >
      {children}
    </ReactErrorBoundary>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useErrorBoundary = () => useReactErrorBoundary()

export default ErrorBoundary
