import { Ajax } from "@newrelic/browser-agent/features/ajax"
import { GenericEvents } from "@newrelic/browser-agent/features/generic_events"
import { JSErrors } from "@newrelic/browser-agent/features/jserrors"
import { Metrics } from "@newrelic/browser-agent/features/metrics"
import { PageViewEvent } from "@newrelic/browser-agent/features/page_view_event"
import { PageViewTiming } from "@newrelic/browser-agent/features/page_view_timing"
import { SessionTrace } from "@newrelic/browser-agent/features/session_trace"
import { Spa } from "@newrelic/browser-agent/features/spa"
import { AgentOptions } from "@newrelic/browser-agent/loaders/agent"
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent"

// Populate using values from NerdGraph
const options: AgentOptions = {
  info: {
    applicationID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
    beacon: import.meta.env.REACT_APP_NR_BEACON_PROXY,
    errorBeacon: import.meta.env.REACT_APP_NR_BEACON_PROXY,
    licenseKey: import.meta.env.REACT_APP_NR_LICENSE_KEY,
    sa: 1,
  },
  init: {
    ajax: {
      deny_list: ["bam.nr-data.net"],
    },
    distributed_tracing: {
      enabled: true,
    },
    privacy: {
      cookies_enabled: true,
    },
    proxy: {
      beacon: import.meta.env.REACT_APP_NR_BEACON_PROXY,
    },
  },
  features: [
    {
      JSErrors,
      Metrics,
      PageViewEvent,
      PageViewTiming,
      Spa,
      Ajax,
      GenericEvents,
      SessionTrace,
    },
  ],
  loader_config: {
    accountID: import.meta.env.REACT_APP_NR_ACCOUNT_ID,
    agentID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
    applicationID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
    licenseKey: import.meta.env.REACT_APP_NR_LICENSE_KEY,
    trustKey: import.meta.env.REACT_APP_NR_ACCOUNT_ID,
  },
}

const instance: BrowserAgent | null = import.meta.env.REACT_APP_NR_LICENSE_KEY
  ? new BrowserAgent(options)
  : null

window.addEventListener("error", (evt) => {
  instance?.noticeError(evt.error)
})
window.addEventListener("unhandledrejection", (evt) => {
  instance?.noticeError(evt.reason)
})

export default instance
