import React, { useEffect } from "react"

import { SignedIn, SignedOut } from "@clerk/clerk-react"
import { Navigate } from "react-router-dom"

import useSession from "@hooks/useSession"

import routes from "@constants/routes"
import { userStatuses } from "@constants/serverEnums"

import ErrorBoundary from "./ErrorBoundary"

interface ProtectedViewProps {
  children: React.ReactNode
}

const ProtectedView: React.FC<ProtectedViewProps> = ({
  children,
}: ProtectedViewProps) => {
  const { clerkOrg, currentUser, orgMember } = useSession()

  useEffect(() => {
    if (currentUser?.id) {
      window.analytics?.identify(currentUser?.id, {
        email: orgMember?.profile?.email,
        username: currentUser?.username,
        event: "Identify User",
      })
    }
  }, [currentUser, orgMember])

  function renderContent(children: React.ReactNode) {
    if (!clerkOrg) {
      return <Navigate to={routes.NO_ORGANIZATION} />
    }
    if (orgMember?.profile?.status === userStatuses.SUSPENDED) {
      return <Navigate to={routes.ACCESS_DENIED} />
    }
    if (!!orgMember && !orgMember?.profile?.firstLogin) {
      return <Navigate to={routes.PROFILE_BUILDER} />
    }
    return children
  }

  return (
    <ErrorBoundary>
      <SignedIn>{renderContent(children)}</SignedIn>
      <SignedOut>
        <Navigate to="/" />
      </SignedOut>
    </ErrorBoundary>
  )
}

export default ProtectedView
