import InputLabel from "@mui/material/InputLabel"
import { styled } from "@mui/material/styles"

const Label = styled(InputLabel)(({ theme }) => ({
  color:
    theme.palette.mode === "dark" ? theme.palette.primary.light : "#1E1F21",
  fontWeight: 600,
  fontSize: theme.typography.body2.fontSize,
  marginBottom: "8px",
}))

export default Label
