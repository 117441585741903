import React from "react"

import { SxProps } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import { toTitleCase } from "@services/modifyStrings"

import { BillStatus, EquipmentStatus, InvoiceStatus } from "@gql/graphql"

type PillColor = {
  bgColor: string
  fontColor: string
  borderColor?: string
}

type ThemeColors = {
  light: PillColor
  dark: PillColor
}

export const pillColors: Record<string, ThemeColors> = {
  green: {
    light: { bgColor: "#9EFAC8", fontColor: "#05612F" },
    dark: { bgColor: "#89D8AD", fontColor: "#07552B" },
  },
  pink: {
    light: { bgColor: "#FCCFDD", fontColor: "#600622" },
    dark: { bgColor: "#D9B3BF", fontColor: "#550820" },
  },
  orange: {
    light: { bgColor: "#FAD29E", fontColor: "#613905" },
    dark: { bgColor: "#D8B689", fontColor: "#553307" },
  },
  yellow: {
    light: { bgColor: "#F9E686", fontColor: "#615205" },
    dark: { bgColor: "#D7C775", fontColor: "#554907" },
  },
  purple: {
    light: { bgColor: "#D4CFFC", fontColor: "#0F0561" },
    dark: { bgColor: "#B7B3D9", fontColor: "#100755" },
  },
  purple2: {
    light: { bgColor: "#4E5CFE", fontColor: "#E2E2E2" },
    dark: { bgColor: "#4E5CFE", fontColor: "#E2E2E2" },
  },
  blue: {
    light: { bgColor: "#9EC8FA", fontColor: "#052F61" },
    dark: { bgColor: "#89ADD8", fontColor: "#072B55" },
  },
  gray: {
    light: { bgColor: "#D9D9D9", fontColor: "#333333" },
    dark: { bgColor: "#BBBBBB", fontColor: "#2E2E2E" },
  },
  red: {
    light: { bgColor: "#FBB2AD", fontColor: "#610B05" },
    dark: { bgColor: "#D89A96", fontColor: "#550C07" },
  },
  white: {
    light: { bgColor: "#FFFFFF", fontColor: "#474747", borderColor: "#DEDEDE" },
    dark: { bgColor: "#FFFFFF", fontColor: "#3F3F3F", borderColor: "#BBBBBB" },
  },
  lavender: {
    light: { bgColor: "#E5B6FB", fontColor: "#440561" },
    dark: { bgColor: "#C69ED8", fontColor: "#3D0755" },
  },
}

// Helper function to get theme-aware colors
export const getPillColor = (color: string, isDarkMode: boolean): PillColor => {
  return (
    pillColors[color]?.[isDarkMode ? "dark" : "light"] ||
    pillColors.gray[isDarkMode ? "dark" : "light"]
  )
}

const stateColors = (isDarkMode: boolean): Record<string, PillColor> => ({
  active: getPillColor("green", isDarkMode),
  pending: getPillColor("yellow", isDarkMode),
  suspended: getPillColor("orange", isDarkMode),

  // PO states
  awaiting_shipment: getPillColor("orange", isDarkMode),
  partially_received: getPillColor("yellow", isDarkMode),
  received: getPillColor("green", isDarkMode),

  // order states
  draft: getPillColor("purple", isDarkMode),
  unfulfilled: getPillColor("yellow", isDarkMode),
  partially_fulfilled: getPillColor("orange", isDarkMode),

  // payment states
  not_paid: getPillColor("orange", isDarkMode),
  partially_paid: getPillColor("yellow", isDarkMode),
  payment_pending: getPillColor("yellow", isDarkMode),
  overdue: getPillColor("red", isDarkMode),
  disputed: getPillColor("purple", isDarkMode),

  // item hierarchies
  parent: getPillColor("purple", isDarkMode),
  variant: getPillColor("blue", isDarkMode),
  single: getPillColor("yellow", isDarkMode),

  // inventory states
  depleted: getPillColor("purple", isDarkMode),
  quarantined: getPillColor("pink", isDarkMode),

  // inventory receiving types
  accepted: getPillColor("green", isDarkMode),
  rejected: getPillColor("red", isDarkMode),

  // item availability states
  unavailable: getPillColor("red", isDarkMode),
  fully_available: getPillColor("green", isDarkMode),
  partially_available: getPillColor("yellow", isDarkMode),

  // picking & packing states
  not_started: getPillColor("red", isDarkMode),
  fully_picked: getPillColor("green", isDarkMode),
  partially_picked: getPillColor("yellow", isDarkMode),
  fully_packed: getPillColor("green", isDarkMode),
  partially_packed: getPillColor("yellow", isDarkMode),

  // job status states
  // not_started (already exists)
  in_progress: getPillColor("yellow", isDarkMode),
  completed: getPillColor("green", isDarkMode),

  // job priority states
  rush: getPillColor("red", isDarkMode),
  normal: getPillColor("gray", isDarkMode),

  // nested inventory states
  nested: getPillColor("orange", isDarkMode),
  child: getPillColor("purple", isDarkMode),

  // jobs
  job_number: getPillColor("lavender", isDarkMode),

  // po number
  po_number: getPillColor("lavender", isDarkMode),

  // quality check states
  pass: getPillColor("green", isDarkMode),
  fail: getPillColor("red", isDarkMode),

  // shipment states
  awaiting_shipment_creation: getPillColor("yellow", isDarkMode),
  awaiting_carrier: getPillColor("blue", isDarkMode),
  awaiting_pickup: getPillColor("pink", isDarkMode),
  awaiting_drop_off: getPillColor("orange", isDarkMode),

  // custom label types
  standard: getPillColor("blue", isDarkMode),
  custom: getPillColor("orange", isDarkMode),

  // quote states
  sent: getPillColor("orange", isDarkMode),
  revisionsNeeded: getPillColor("yellow", isDarkMode),
  expired: getPillColor("red", isDarkMode),
  closedWon: getPillColor("green", isDarkMode),
  closedLost: getPillColor("gray", isDarkMode),

  // invoice states
  [InvoiceStatus.Invoiced.toLowerCase()]: getPillColor("green", isDarkMode),
  [InvoiceStatus.InvoicePending.toLowerCase()]: getPillColor(
    "yellow",
    isDarkMode,
  ),
  [InvoiceStatus.NotInvoiced.toLowerCase()]: getPillColor("red", isDarkMode),
  [InvoiceStatus.PartiallyInvoiced.toLowerCase()]: getPillColor(
    "purple",
    isDarkMode,
  ),

  // bill states
  [BillStatus.Billed.toLowerCase()]: getPillColor("green", isDarkMode),
  [BillStatus.BillPending.toLowerCase()]: getPillColor("yellow", isDarkMode),
  [BillStatus.NotBilled.toLowerCase()]: getPillColor("red", isDarkMode),
  [BillStatus.PartiallyBilled.toLowerCase()]: getPillColor(
    "purple",
    isDarkMode,
  ),

  // equipment states
  [EquipmentStatus.Retired.toLowerCase()]: getPillColor("gray", isDarkMode),
  [EquipmentStatus.Inactive.toLowerCase()]: getPillColor("orange", isDarkMode),
  [EquipmentStatus.Maintenance.toLowerCase()]: getPillColor(
    "yellow",
    isDarkMode,
  ),
})

interface PillProps {
  status: string

  color?: string
  dataCy?: string
  endAdornment?: React.ReactNode
  icon?: React.ReactNode
  sx?: SxProps
  sxText?: SxProps
  label?: string | React.ReactNode
  onClick?: (event: React.MouseEvent<never>) => void
  isDarkMode?: boolean
}

const Pill: React.FC<PillProps> = ({
  color,
  dataCy,
  endAdornment,
  icon,
  label,
  onClick,
  status,
  sx,
  sxText,
  isDarkMode = false,
}: PillProps) => {
  let finalLabel = label
  const key = typeof status === "string" ? status?.toLowerCase() : "unknown"
  let stateColor = stateColors(isDarkMode)[key]

  if (stateColor === undefined) {
    stateColor = getPillColor("gray", isDarkMode)
  }

  // if color is passed in override them all
  if (color) {
    stateColor = getPillColor(color, isDarkMode)
  }

  if (label && typeof label === "string") {
    finalLabel = toTitleCase(label)
  }

  return (
    <Box
      data-testid="pill"
      flexDirection="row"
      display="flex"
      sx={{
        borderRadius: "4px",
        backgroundColor: stateColor?.bgColor,
        border: stateColor?.borderColor
          ? `1px solid ${stateColor?.borderColor}`
          : "",
        color: stateColor?.fontColor,
        padding: "2px 8px",
        textAlign: "center",
        width: "fit-content",
        ...(!!onClick && { cursor: "pointer" }),
        ...sx,
      }}
      onClick={onClick}
    >
      <Typography
        data-cy={dataCy || "status"}
        sx={{
          alignItems: "center",
          display: "flex",
          textTransform: "capitalize",
          ...sxText,
        }}
        variant="overline"
      >
        {icon}
        {finalLabel || toTitleCase(status)}
      </Typography>
      {endAdornment}
    </Box>
  )
}

export default Pill
