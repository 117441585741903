import { RutterConnection } from "@gql/graphql"

// These match the Rutter platform strings
export enum IntegrationType {
  QUICKBOOKS = "QUICKBOOKS",
  QUICKBOOKS_DESKTOP = "QUICKBOOKS_DESKTOP",
}
export const integrationNames = {
  [IntegrationType.QUICKBOOKS]: "QuickBooks Online",
  [IntegrationType.QUICKBOOKS_DESKTOP]: "QuickBooks Desktop",
}

export interface IntegrationComponent {
  node?: RutterConnection
  refetchConnections: () => void
  isLoading: boolean
}
export interface IntegrationConfig {
  platforms: string[]
  component: React.FC<IntegrationComponent>
}
