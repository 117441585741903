import React from "react"

import MuiAppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import Stack from "@mui/system/Stack"
import { Loader } from "lucide-react"
import { useTranslation } from "react-i18next"

import { useAppDrawer } from "@hooks/useAppDrawer"

import FavoriteFeature from "@components/FavoriteFeature"
import { IconWrapper } from "@components/IconWrapper"
import { useThemeContext } from "@providers/AppThemeProvider"

import { colors } from "@constants/colors"
import { FeatureNavItem } from "@constants/features"

import { appDrawerWidth } from "./AppDrawer"
import { topNavigationHeight } from "./TopNavigation"

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light" ? colors.STANDARD_WHITE : "none",
  boxShadow: "none",
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[900]
      : theme.palette.grey[100],
  paddingTop: 8,
}))

interface AppbarProps {
  action?: React.ReactNode
  favoriteFeature?: string
  feature?: FeatureNavItem
}

export const appBarHeight = 93

const Appbar: React.FC<AppbarProps> = ({
  action,
  favoriteFeature,
  feature,
}: AppbarProps) => {
  const { t } = useTranslation()
  const { open } = useAppDrawer()
  const { darkMode } = useThemeContext()

  return (
    <AppBar
      sx={{
        // has to match color of background Stack in UnprotectedLayout
        backgroundColor: darkMode
          ? colors.BG_DARK_GRAY_2
          : colors.BG_LIGHT_GRAY_4,
        border: 0,
        left: open ? appDrawerWidth : 0,
        pt: 2,
        pb: 0,
        top: `${topNavigationHeight}px`,
        zIndex: 3,
      }}
      variant="outlined"
      elevation={0}
    >
      <Toolbar variant="dense">
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            width: open ? `calc(100% - ${appDrawerWidth}px)` : "100%",
          }}
        >
          <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
            <Typography
              variant="h4"
              sx={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconWrapper size={18} sx={{ color: "inherit", mr: 1 }}>
                {feature?.icon ?? <Loader />}
              </IconWrapper>

              {feature ? (
                t(feature.translatedTextKey)
              ) : (
                <Skeleton width={200} />
              )}
            </Typography>

            {favoriteFeature && (
              <FavoriteFeature feature={favoriteFeature} sx={{ ml: 1 }} />
            )}
          </Stack>

          {action && <Box>{action}</Box>}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar
