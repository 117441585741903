import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react"

import CssBaseline from "@mui/material/CssBaseline"
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { TriangleAlert } from "lucide-react"

import useSession from "@hooks/useSession"

import { IconWrapper } from "@components/IconWrapper"

import ls, { localKeys } from "@services/localStorage"

import { colors, neutral, orange, yellow } from "@constants/colors"
import { viewModes } from "@constants/serverEnums"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    caption2: React.CSSProperties
    overline2: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties
    overline2?: React.CSSProperties
    body1Link?: React.CSSProperties
    body2Link?: React.CSSProperties
    buttonSm?: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    caption2: true
    overline2: true
    body1Link: true
    body2Link: true
    buttonSm: true
  }
}

interface ThemeContextType {
  darkMode: boolean
  toggleDarkMode: () => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export const themeOptions = (darkMode: boolean): ThemeOptions => ({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          severity: {
            warning: {
              root: {
                color: darkMode ? yellow[100] : yellow[800],
                backgroundColor: darkMode ? yellow[800] : yellow[200],
              },
            },
          },
        },
      },
      defaultProps: {
        iconMapping: {
          warning: (
            <IconWrapper
              sx={{
                fontSize: "inherit",
                color: darkMode ? yellow[100] : yellow[800],
              }}
              size={24}
            >
              <TriangleAlert />
            </IconWrapper>
          ),
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "FFFFFF",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: "0.8125rem", // body2
          "& ::placeholder": {
            fontSize: "0.8125rem", // body2
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(30, 30, 30, 0.55)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "8px 12px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          // needed so only one modal overlay (the top most) is shown when many modals are open
          '&[aria-hidden="true"] .MuiBackdrop-root': {
            opacity: "0 !important",
            transition: "opacity 225ms ease-in-out !important",
          },
        },
        paper: ({ theme }) => ({
          backgroundColor:
            theme.palette.mode === "dark" ? neutral[900] : neutral[50],
          backgroundImage: "none",
          border:
            theme.palette.mode === "dark"
              ? `1px solid ${neutral[800]}`
              : `1px solid ${neutral[200]}`,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            fontSize: "0.8125rem", // body2
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        input: {
          fontSize: "14px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "14px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "0.8125rem", // body2
          "& ::placeholder": {
            fontSize: "0.8125rem", // body2
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
  palette: {
    mode: darkMode ? "dark" : "light",
    primary: {
      main: darkMode ? colors.STANDARD_WHITE : colors.PRIMARY, // Primary color (for buttons, etc.)
      contrastText: darkMode ? colors.PRIMARY : colors.STANDARD_WHITE, // Text color on the primary background
    },
    secondary: {
      main: darkMode ? colors.DARK_SECONDARY : colors.SECONDARY, // Secondary color (for accents, etc.)
      contrastText: colors.STANDARD_WHITE, // Text color on the secondary background
    },
    error: {
      main: colors.BUTTON_RED, // Error color
    },
    warning: {
      main: orange[600], // Warning color
    },
    // info: {
    //   main: "#2196f3", // Info color
    // },
    success: {
      main: colors.STATUS_GREEN_TXT, // Success color
    },
    text: {
      primary: darkMode ? colors.STANDARD_WHITE : colors.TEXT_DARK, // Primary text color
      secondary: darkMode ? colors.STANDARD_WHITE : colors.TEXT_DARK_GRAY, // Secondary text color
    },
    // background: {
    //   default: "#f0f0f0", // Default background color
    //   paper: "#fff", // Paper (card) background color
    // },
    divider: darkMode ? colors.STANDARD_WHITE : colors.BORDER_GRAY, // Divider color
    // action: {
    //   active: darkMode ? colors.DARK_SECONDARY : colors.SECONDARY, // Active state color (for buttons, etc.)
    //   hover: darkMode ? colors.DARK_SECONDARY : colors.SECONDARY, // Hover state color (for buttons, etc.)
    // },
    grey: neutral,
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily:
      "Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    h1: {
      letterSpacing: 0,
      fontSize: "2rem", // 32px
      lineHeight: "2.25rem", // 36px,
      fontWeight: 500,
    },
    h2: {
      letterSpacing: 0,
      fontSize: "1.75rem", // 28px
      lineHeight: "2rem", // 32px
      fontWeight: 500,
    },
    h3: {
      letterSpacing: 0,
      fontSize: "1.5rem", // 24px
      lineHeight: "1.75rem", // 28px
      fontWeight: 500,
    },
    h4: {
      letterSpacing: 0,
      fontSize: "1.25rem", // 20px
      lineHeight: "1.5rem", // 24px
      fontWeight: 500,
    },
    h5: {
      letterSpacing: 0,
      fontSize: "1rem", // 16px
      lineHeight: "1.25rem", // 20px
      fontWeight: 500,
    },
    h6: {
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1.25rem", // 20px
      fontWeight: 600,
    },
    subtitle1: {
      // Default
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1rem", // 16px, to align with icons
      fontWeight: 600,
    },
    subtitle2: {
      // Emphasis
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1rem", // 16px, to align with icons
      fontWeight: 500,
    },
    body1: {
      // Default
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1.25rem", // 20px
      fontWeight: 400,
    },
    body1Link: {
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1.25rem", // 20px
      fontWeight: 400,
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationSkipInk: "auto",
      textDecorationThickness: "auto",
      textUnderlineOffset: "0.25rem",
      textUnderlinePosition: "from-font",
    },
    body2: {
      // Emphasis
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1.25rem", // 20px
      fontWeight: 600,
    },
    body2Link: {
      letterSpacing: 0,
      fontSize: "0.8125rem", // 13px
      lineHeight: "1.25rem", // 20px
      fontWeight: 600,
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationSkipInk: "auto",
      textDecorationThickness: "auto",
      textUnderlineOffset: "0.25rem",
      textUnderlinePosition: "from-font",
    },
    button: {
      // Default
      fontSize: "0.8125rem", // 13px
      lineHeight: "1rem", // 16px, to align with 16x16 icons
      fontWeight: 500,
      textTransform: "none", // Regular capitalization
    },
    buttonSm: {
      // Small
      fontSize: "0.6875rem", // 11px
      lineHeight: "0.75rem", // 12px, to align with 12x12 icons
      fontWeight: 500,
      textTransform: "none", // Regular capitalization
    },
    caption: {
      // Default
      letterSpacing: 0,
      fontSize: "0.75rem", // 12px
      lineHeight: "1rem", // 16px
      fontWeight: 400,
    },
    caption2: {
      // Emphasis
      letterSpacing: 0,
      fontSize: "0.75rem", // 12px
      lineHeight: "1rem", // 16px
      fontWeight: 600,
    },
    overline: {
      // Default
      letterSpacing: 0,
      fontSize: "0.6875rem", // 11px
      lineHeight: "1rem", // 16px
      fontWeight: 400,
    },
    overline2: {
      // Emphasis
      letterSpacing: 0,
      fontSize: "0.6875rem", // 11px
      lineHeight: "1rem", // 16px
      fontWeight: 600,
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThemeContext: any = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider")
  }
  return context
}

interface AppThemeProviderProps {
  children: React.ReactNode
}

const AppThemeProvider: React.FC<AppThemeProviderProps> = ({
  children,
}: AppThemeProviderProps) => {
  const { orgMember } = useSession()
  const serverSavedDark = orgMember?.settings?.viewMode === viewModes.DARK
  const lsSavedDark = ls.getItem(localKeys.VIEW_MODE) === viewModes.DARK
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  // use user's browser preference first
  // fall back to local storage value if it exists
  // use the value from the server last (as it is slowest)
  const [darkMode, setDarkMode] = useState(
    serverSavedDark || lsSavedDark || prefersDarkMode,
  )

  useEffect(() => {
    setDarkMode(serverSavedDark)
  }, [setDarkMode, serverSavedDark])

  const memoizedValue = useMemo(() => {
    const themeValue: ThemeContextType = {
      darkMode,
      toggleDarkMode: () => {
        setDarkMode((prevMode) => !prevMode)
      },
    }
    return themeValue
  }, [darkMode, setDarkMode])

  const theme = React.useMemo(
    () => createTheme(themeOptions(darkMode)),
    [darkMode],
  )

  return (
    <ThemeContext.Provider value={memoizedValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default AppThemeProvider
