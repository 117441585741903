import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react"

import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

import { useSnackbar } from "@providers/SnackbarProvider"
import {
  integrationNames,
  IntegrationType,
} from "@views/organizationSettings/Integrations/shared/types"

import {
  RutterConnection,
  RutterConnectionStatus,
  RutterSyncStatusDocument,
  RutterSyncStatusResponse,
} from "@gql/graphql"

interface RutterInitialSyncContextType {
  startInitialSyncStatusPoll: (connection: RutterConnection) => void
  stopInitialSyncStatusPoll: () => void
  getInitialSyncStatus: () => RutterSyncStatusResponse | undefined
  setShowReminder: (show: boolean) => void
  isPolling: boolean
  error: boolean
}

const RutterInitialSyncContext = createContext<RutterInitialSyncContextType>({
  startInitialSyncStatusPoll: () => {},
  stopInitialSyncStatusPoll: () => {},
  getInitialSyncStatus: () => undefined,
  setShowReminder: () => {},
  isPolling: false,
  error: false,
})

export const useRutterInitialSync = (): RutterInitialSyncContextType =>
  useContext(RutterInitialSyncContext)

const POLL_INTERVAL = 5000

export const RutterInitialSyncStatusProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const { t } = useTranslation()
  const { openSnackbar } = useSnackbar()
  const [connection, setConnection] = useState<RutterConnection | null>(null)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [lastSyncStatus, setLastSyncStatus] =
    useState<RutterSyncStatusResponse>()

  const { data, error } = useQuery(RutterSyncStatusDocument, {
    skip: !connection?.id,
    variables: { input: { connectionId: connection?.id || "" } },
    pollInterval: POLL_INTERVAL,
    onError: () => {
      const integrationName = connection?.platform
        ? integrationNames[connection.platform as IntegrationType] || ""
        : ""
      openSnackbar(t("integrations:completed_Setup", { integrationName }), {
        severity: "error",
        title: t("integrations:completed_Setup", { integrationName }),
      })
      setConnection(null)
    },
  })

  useEffect(() => {
    if (data?.rutterSyncStatus) {
      setLastSyncStatus(data.rutterSyncStatus)
    }
  }, [data])

  useEffect(() => {
    const status = data?.rutterSyncStatus?.connectionStatus
    const integrationName = connection?.platform
      ? integrationNames[connection.platform as IntegrationType] || ""
      : ""

    if (status === RutterConnectionStatus.Ready && showNotification) {
      openSnackbar(
        t("integrations:importing_CompleteNotification", { integrationName }),
        {
          severity: "success",
          title: t("integrations:completed_Setup", { integrationName }),
        },
      )
      setShowNotification(false)
      setConnection(null)
    }
  }, [
    data?.rutterSyncStatus?.connectionStatus,
    showNotification,
    openSnackbar,
    t,
    connection?.platform,
  ])

  const contextValue = useMemo(() => {
    const startInitialSyncStatusPoll = (
      newConnection: RutterConnection,
    ): void => {
      setConnection(newConnection)
      setShowNotification(false)
    }

    const stopInitialSyncStatusPoll = (): void => {
      setConnection(null)
    }

    const setShowReminder = (show: boolean): void => {
      setShowNotification(show)
    }

    const getInitialSyncStatus = (): RutterSyncStatusResponse | undefined => {
      return lastSyncStatus || data?.rutterSyncStatus
    }

    return {
      startInitialSyncStatusPoll,
      stopInitialSyncStatusPoll,
      getInitialSyncStatus,
      setShowReminder,
      isPolling: !!connection,
      error: !!error,
    }
  }, [connection, error, lastSyncStatus, data?.rutterSyncStatus])

  useEffect(() => {
    return () => {
      setConnection(null)
    }
  }, [])

  return (
    <RutterInitialSyncContext.Provider value={contextValue}>
      {children}
    </RutterInitialSyncContext.Provider>
  )
}
