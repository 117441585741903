import { PLACEHOLDER } from "@constants/globalStrings"

/**
 * Capitalizes the first letter of a string and makes the rest lowercase
 * @example
 * Input: "hELLo"
 * Output: "Hello"
 */
export function capitalizeFirstLetter(input: string): string {
  if ((input && input?.length === 0) || !input) {
    return input
  }

  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

/**
 * Converts a snake_case string to display format by replacing underscores with spaces and capitalizing first letter
 * @example
 * Input: "hello_world"
 * Output: "Hello world"
 */
export function snakeCaseToDisplayValue(input: string): string {
  if ((input && input?.length === 0) || !input) {
    return input
  }

  return capitalizeFirstLetter(input?.replace(/_/g, " "))
}

/**
 * Returns null if field matches placeholder, otherwise returns the field value
 * @example
 * Input: "PLACEHOLDER"
 * Output: null
 */
export function cleanField(field: string): string | null {
  return field === PLACEHOLDER ? null : field
}

/**
 * Formats a number to have 2 decimal places only if it has decimals
 * @example
 * Input: 123.456
 * Output: "123.46"
 * Input: 123
 * Output: "123"
 */
export function formatToTwoDecimals(value: number): string {
  return value % 1 === 0 ? `${value}` : `${value.toFixed(2)}`
}

/**
 * Converts PascalCase to Title Case by adding spaces before capital letters
 * @example
 * Input: "HelloWorld"
 * Output: "Hello World"
 */
export const pascalCaseToTitleCase = (str: string): string =>
  str.replace(/([A-Z][a-z])/g, " $1").trim()

/**
 * Converts camelCase to Sentence case by adding spaces and capitalizing first letter
 * @example
 * Input: "helloWorld"
 * Output: "Hello world"
 */
export const camelCaseToSentenceCase = (value: string): string => {
  // Add space before capital letters and convert to lowercase
  const withSpaces = value
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .trim()
  // Capitalize only the first letter of the entire string
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1)
}

/**
 * Converts any string format (snake_case, camelCase, PascalCase) to Title Case
 * @example
 * Input: "hello_world" or "helloWorld" or "HelloWorld"
 * Output: "Hello World"
 */
export const toTitleCase = (str: string): string => {
  // Replace underscores with spaces
  let modifiedStr = str?.replace(/_/g, " ")
  // Insert a space before any uppercase letter that follows a lowercase letter
  modifiedStr = modifiedStr?.replace(/([a-z])([A-Z])/g, "$1 $2")
  // Insert a space before any uppercase letter that follows another uppercase letter and is followed by a lowercase letter
  modifiedStr = modifiedStr?.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  // Capitalize the first letter of each word
  modifiedStr = modifiedStr?.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  })
  return modifiedStr
}

/**
 * Converts a snake_case string to camelCase
 * @example
 * Input: "hello_world"
 * Output: "helloWorld"
 */
export const snakeCaseToCamelCase = (str: string): string =>
  str
    .replace(/^_+/, "") // Remove leading underscores
    .replace(/_+$/, "") // Remove trailing underscores
    .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
