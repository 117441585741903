/* eslint-disable import/first */

// initialize newrelic before anything else
// eslint-disable-next-line import/order, import/newline-after-import
import newrelic from "./newrelic"
newrelic?.run()

import React from "react"

import ReactDOM from "react-dom/client"

import App from "./App"

import "@fontsource/inter/latin-100.css"
import "@fontsource/inter/latin-200.css"
import "@fontsource/inter/latin-300.css"
import "@fontsource/inter/latin-400.css"
import "@fontsource/inter/latin-500.css"
import "@fontsource/inter/latin-600.css"
import "@fontsource/inter/latin-700.css"
import "@fontsource/inter/latin-800.css"
import "@fontsource/inter/latin-900.css"

/* eslint-enable import/first */
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
