export const neutral = {
  0: "#ffffff",
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#e5e5e5",
  300: "#d4d4d4",
  400: "#a3a3a3",
  500: "#737373",
  600: "#525252",
  700: "#404040",
  800: "#262626",
  900: "#171717",
  1000: "#000000",
}

export const fuschia = {
  50: "#fdf4ff",
  100: "#fae8ff",
  200: "#f5d0fe",
  300: "#f0abfc",
  400: "#e879f9",
  500: "#d946ef",
  600: "#c026d3",
  700: "#a21caf",
  800: "#86198f",
  900: "#701a75",
}

export const purple = {
  50: "#f5f3ff",
  100: "#ede9fe",
  200: "#ddd6fe",
  300: "#c4b5fd",
  400: "#a78bfa",
  500: "#8b5cf6",
  600: "#7c3aed",
  700: "#6d28d9",
  800: "#5b21b6",
  900: "#4c1d95",
}

export const blue = {
  50: "#eff6ff",
  100: "#dbeafe",
  200: "#bfdbfe",
  300: "#93c5fd",
  400: "#60a5fa",
  500: "#3b82f6",
  600: "#2563eb",
  700: "#1d4ed8",
  800: "#1e40af",
  900: "#1e3a8a",
}

export const teal = {
  50: "#f0fdfa",
  100: "#ccfbf1",
  200: "#99f6e4",
  300: "#5eead4",
  400: "#2dd4bf",
  500: "#14b8a6",
  600: "#0d9488",
  700: "#0f766e",
  800: "#115e59",
  900: "#134e4a",
}

export const pink = {
  50: "#fdf2f8",
  100: "#fce7f3",
  200: "#fbcfe8",
  300: "#f9a8d4",
  400: "#f472b6",
  500: "#ec4899",
  600: "#db2777",
  700: "#be185d",
  800: "#9d174d",
  900: "#831843",
}

export const red = {
  50: "#fef2f2",
  100: "#fee2e2",
  200: "#fecaca",
  300: "#fca5a5",
  400: "#f87171",
  500: "#ef4444",
  600: "#dc2626",
  700: "#b91c1c",
  800: "#991b1b",
  900: "#7f1d1d",
}

export const orange = {
  50: "#fff7ed",
  100: "#ffedd5",
  200: "#fed7aa",
  300: "#fdba74",
  400: "#fb923c",
  500: "#f97316",
  600: "#ea580c",
  700: "#c2410c",
  800: "#9a3412",
  900: "#7c2d12",
}

export const yellow = {
  50: "#fffbeb",
  100: "#fef3c7",
  200: "#fde68a",
  300: "#fcd34d",
  400: "#fbbf24",
  500: "#f59e0b",
  600: "#d97706",
  700: "#b45309",
  800: "#92400e",
  900: "#78350f",
}

export const green = {
  50: "#ecfdf5",
  100: "#d1fae5",
  200: "#a7f3d0",
  300: "#6ee7b7",
  400: "#34d399",
  500: "#10b981",
  600: "#059669",
  700: "#047857",
  800: "#065f46",
  900: "#064e3b",
}

// DEPRECATED: DO NOT USE. USE THE NEW COLORS ABOVE.
export const colors = {
  BG_DARK_GRAY_2: neutral[900],
  BG_DARK_GRAY: neutral[900],
  BG_GRAY: neutral[200],
  BG_LIGHT_GRAY_2: neutral[200],
  BG_LIGHT_GRAY_3: neutral[50],
  BG_LIGHT_GRAY_4: neutral[50],
  BG_LIGHT_GRAY: neutral[100],
  BORDER_GRAY: neutral[200],
  BUTTON_RED: red[500],
  DARK_SECONDARY: blue[400],
  NOTICE_TXT: yellow[800],
  PRIMARY: neutral[900],
  SECONDARY: blue[400],
  STANDARD_WHITE: neutral[0],
  STATUS_GREEN_BG_2: green[200],
  STATUS_GREEN_BG: green[300],
  STATUS_GREEN_TXT: green[800],
  STATUS_RED_BG_2: red[200],
  STATUS_RED_BG: red[300],
  STATUS_RED_TXT: red[800],
  TEXT_DARK_GRAY: neutral[600],
  TEXT_DARK: neutral[900],
  TEXT_LIGHT_GRAY_2: neutral[400],
  TEXT_LIGHT_GRAY: neutral[500],
  YELLOW: yellow[200],
}

export function getColorForValueSign(
  value: number | string | null | undefined,
): string | null {
  if (value === null || value === undefined) {
    return null
  }

  const numericValue = typeof value === "string" ? parseInt(value, 10) : value
  return numericValue < 0 ? colors.STATUS_RED_TXT : null
}
