// Provides data to application using Apollo || React-Query.

import React, { useMemo } from "react"

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  Observable,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"
import { useAuth } from "@clerk/clerk-react"
import { sha256 } from "crypto-hash"
import {
  QueryClient,
  QueryClientProvider as RQQueryClientProvider,
} from "react-query"

import { isDevEnv } from "@helpers/isProductionEnv"
import { logger } from "@helpers/logger"

import { mergeConnectionResults } from "../helpers/mergeConnectionResults"

if (!import.meta.env.REACT_APP_SERVER_URL) {
  throw new Error("Missing Server Url")
}

const queryClient = new QueryClient()

interface DataProviderProps {
  children: React.ReactNode
}

const DataProvider: React.FC<DataProviderProps> = ({
  children,
}: DataProviderProps) => {
  const { getToken } = useAuth()

  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const accessToken = await getToken()

      if (!accessToken) return headers

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    })

    const errorLink = onError(({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        const unauthenticatedError = graphQLErrors.find(
          (err) => err.extensions?.code === "UNAUTHENTICATED",
        )
        if (unauthenticatedError) {
          return new Observable((observer) => {
            getToken()
              .then((accessToken) => {
                if (accessToken) {
                  // Modify the operation context with a new token
                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${accessToken}`,
                    },
                  })
                }
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }

                // Retry the last failed request
                forward(operation).subscribe(subscriber)
              })
              .catch((error) => {
                // No refresh or client token available, we force the user to log in
                observer.error(error)
              })
          })
        }

        const conflictError = graphQLErrors.find(
          (err) => err.extensions?.code === "CONFLICT",
        )
        if (conflictError) {
          logger.error("Conflict error", operation?.operationName)
        }
      }
      // Return undefined for non-UNAUTHENTICATED errors
      return undefined
    })

    const httpLink = createPersistedQueryLink({ sha256 }).concat(
      createHttpLink({
        // the 'credentials' var controls if cookies are sent.
        // possible values: "include" and "same-origin"
        // the reason it's called 'credentials' is because this feature was specifically built
        // for allowing cookies that manage user sessions, but it's for any cookie
        credentials: "same-origin",
        uri: `${import.meta.env.REACT_APP_SERVER_URL}/graphql`,
      }),
    )

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      connectToDevTools: isDevEnv(),
      cache: new InMemoryCache({
        typePolicies: {
          Organization: {
            fields: {
              members: {
                keyArgs: ["status", "search", "order"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              reports: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
            },
          },
          OrganizationReports: {
            fields: {
              openOrderItems: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              shippedPickedItems: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
            },
          },
          ItemRouting: {
            fields: {
              routingOperations: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
            },
          },
          Bom: {
            fields: {
              routingOperations: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
            },
          },
          Job: {
            fields: {
              itemsToPick: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              pickedJobItems: {
                keyArgs: [],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
            },
          },

          ColumnWidth: {
            keyFields: false, // disable caching for this type since they don't have a unique id
          },
          Query: {
            fields: {
              items: {
                keyArgs: [
                  "clerkOrganizationId",
                  "itemType",
                  "status",
                  "search",
                  "order",
                  "isDefault",
                  "isParent",
                  "vendorIds",
                  "excludeItemIds",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              purchaseOrders: {
                keyArgs: [
                  "clerkOrganizationId",
                  "companyIds",
                  "order",
                  "search",
                  "statuses",
                  "receivingStatuses",
                  "itemIds",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              orders: {
                keyArgs: [
                  "clerkOrganizationId",
                  "companyIds",
                  "order",
                  "search",
                  "orderStatuses",
                  "paymentStatus",
                  "itemIds",
                  "itemId",
                  "relatedOrdersByShippingAddressOfOrderId",
                  "orderIds",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              companies: {
                keyArgs: [
                  "clerkOrganizationId",
                  "type",
                  "status",
                  "search",
                  "order",
                  "companyIds",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              inventories: {
                keyArgs: [
                  "clerkOrganizationId",
                  "createdAfter",
                  "createdBefore",
                  "excludeNestedInventory",
                  "excludeNestedInventoryExceptDirectDescendants",
                  "hasStock",
                  "itemIds",
                  "jobIds",
                  "order",
                  "parentId",
                  "purchaseOrderIds",
                  "receivingStatuses",
                  "search",
                  "storageTypes",
                  "warehouseLocationIds",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              contacts: {
                keyArgs: [
                  "clerkOrganizationId",
                  "type",
                  "search",
                  "ownerOrganizationProfileIds",
                  "hasOwner",
                  "order",
                  "contactIds",
                  "companyIds",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              organization: {
                keyArgs: ["organizationId"],
                merge(_existing, incoming) {
                  return incoming
                },
              },
              operations: {
                keyArgs: ["clerkOrganizationId", "status", "search", "order"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              warehouseLocations: {
                keyArgs: [
                  "clerkOrganizationId",
                  "locationCode",
                  "description",
                  "status",
                  "codeSerialNumber",
                  "tags",
                  "search",
                  "order",
                  "isWorkCenter",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              itemVendors: {
                keyArgs: [
                  "clerkOrganizationId",
                  "itemId",
                  "vendorIds",
                  "search",
                  "order",
                  "status",
                ],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              itemRouting: {
                keyArgs: ["clerkOrganizationId", "itemId"],
                merge(_existing, incoming) {
                  return incoming
                },
              },
              bom: {
                keyArgs: ["clerkOrganizationId", "itemId"],
                merge(_existing, incoming) {
                  return incoming
                },
              },
              jobs: {
                keyArgs: ["itemIds", "statuses", "search", "order"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              qualityChecks: {
                keyArgs: ["jobIds"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              shipments: {
                keyArgs: ["search", "shippingStatuses", "order", "companyIds"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              boms: {
                keyArgs: ["itemIds"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              uom: {
                keyArgs: ["types"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              workOrders: {
                keyArgs: ["search", "statuses", "order"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              workOrderLogIntervals: {
                keyArgs: ["jobId"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              packedItems: {
                keyArgs: ["shipmentStatuses", "isInvoiced", "orderIds"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
              pickedJobItems: {
                keyArgs: ["jobStatuses"],
                merge: (existing, incoming) =>
                  mergeConnectionResults(existing, incoming),
              },
            },
          },
        },
      }),
    })
  }, [getToken])

  return (
    <ApolloProvider client={client}>
      <RQQueryClientProvider client={queryClient}>
        {children}
      </RQQueryClientProvider>
    </ApolloProvider>
  )
}

export default DataProvider
