import React, { useState, useEffect } from "react"

import { useMutation } from "@apollo/client"
import { SxProps } from "@mui/material"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { Star } from "lucide-react"

import useSession from "@hooks/useSession"

import ErrorBoundary from "@components/ErrorBoundary"
import { IconWrapper } from "@components/IconWrapper"
import { getPillColor } from "@components/Pill"
import { useThemeContext } from "@providers/AppThemeProvider"

import {
  StarFeatureDocument,
  UnstarFeatureDocument,
  FeatureEdge,
  Features,
} from "@gql/graphql"

import { colors } from "@constants/colors"

interface FavoriteFeatureProps {
  feature: string
  sx?: SxProps
}

const FavoriteFeature: React.FC<FavoriteFeatureProps> = ({
  feature,
  sx,
}: FavoriteFeatureProps) => {
  const { darkMode } = useThemeContext()
  const { orgMember, refetchData } = useSession()
  const [starredFeatures, setStarredFeatures] = useState<FeatureEdge[]>(
    orgMember?.settings?.starredFeatures?.edges || [],
  )
  const [isOptimisticallyStarred, setIsOptimisticallyStarred] = useState<
    boolean | null
  >(null)

  const isStarred =
    isOptimisticallyStarred !== null
      ? isOptimisticallyStarred
      : starredFeatures?.some(
          (edge: FeatureEdge) => edge.node?.name === feature,
        )

  const [starFeature] = useMutation(StarFeatureDocument)
  const [unStarFeature] = useMutation(UnstarFeatureDocument)

  useEffect(() => {
    if (isOptimisticallyStarred === null) {
      setStarredFeatures(orgMember?.settings?.starredFeatures?.edges ?? [])
    }
  }, [orgMember?.settings?.starredFeatures?.edges, isOptimisticallyStarred])

  const handleStarClick = (feature: string) => {
    setIsOptimisticallyStarred(true)

    starFeature({
      variables: {
        input: {
          feature: feature as Features,
        },
      },
      onCompleted: () => {
        refetchData()
      },
      onError: () => {
        setIsOptimisticallyStarred(false)
      },
    })
  }

  const handleUnstarClick = (feature: string) => {
    setIsOptimisticallyStarred(false)

    unStarFeature({
      variables: {
        input: {
          feature: feature as Features,
        },
      },
      onCompleted: () => {
        refetchData()
      },
      onError: () => {
        setIsOptimisticallyStarred(true)
      },
    })
  }

  return (
    <ErrorBoundary>
      <Box sx={sx}>
        {isStarred ? (
          <IconButton
            aria-label="unfavorite feature"
            onClick={() => handleUnstarClick(feature)}
            sx={{
              cursor: "pointer",
            }}
          >
            <IconWrapper size={16}>
              <Star
                fill={getPillColor("yellow", darkMode).bgColor}
                stroke={getPillColor("yellow", darkMode).fontColor}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </IconWrapper>
          </IconButton>
        ) : (
          <IconButton
            aria-label="favorite feature"
            onClick={() => handleStarClick(feature)}
            sx={{
              cursor: "pointer",
            }}
          >
            <IconWrapper size={16}>
              <Star
                stroke={darkMode ? colors.STANDARD_WHITE : "#7E869A"}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </IconWrapper>
          </IconButton>
        )}
      </Box>
    </ErrorBoundary>
  )
}

export default FavoriteFeature
