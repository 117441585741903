import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import MuiSelect from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import cc from "currency-codes"
import getSymbolFromCurrency from "currency-symbol-map"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

import { DEFAULT_CURRENCY } from "@constants/globalStrings"

export function getCurrencySymbol(currencyCode?: string): string {
  if (!currencyCode) {
    return getSymbolFromCurrency(DEFAULT_CURRENCY) || DEFAULT_CURRENCY
  }

  return getSymbolFromCurrency(currencyCode) || currencyCode
}

type CurrencyType = {
  code: string
  label: string
}

interface CurrenciesSelectProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: string
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string
  label?: string
  loading?: boolean
  name: string
}

const CurrenciesSelect: React.FC<CurrenciesSelectProps> = ({
  control,
  defaultValue = "US",
  errors,
  helperText,
  label = "Currencies",
  loading,
  name,
}: CurrenciesSelectProps) => {
  const { t } = useTranslation()
  const translatedLabel = label || t("currenciesLabel")
  const Currencies = cc?.data
  const transformedCurrencies: readonly CurrencyType[] = Object.entries(
    Currencies,
  ).map(([_code, currency]) => ({
    code: currency.code,
    label: currency.currency,
  }))

  const sortedCurrencies = [...transformedCurrencies].sort((a, b) =>
    a.label.localeCompare(b.label),
  )

  const customFilterOptions = (
    options: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    { inputValue }: { inputValue: string },
  ) => {
    return options.filter((option: CurrencyType) => {
      const optionString = `${option.code} ${option.label}`.toLowerCase()
      const inputValueLower = inputValue.toLowerCase()
      return optionString.includes(inputValueLower)
    })
  }

  if (loading) {
    return (
      <InputContainerDiv>
        <Label>{translatedLabel}</Label>
        <MuiSelect variant="outlined" fullWidth size="small" disabled />
      </InputContainerDiv>
    )
  }

  return (
    <InputContainerDiv>
      <Label>{translatedLabel}</Label>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            disablePortal
            filterOptions={customFilterOptions}
            options={sortedCurrencies || []}
            getOptionLabel={(option) =>
              option?.label ??
              sortedCurrencies.find(({ code }) => code === option)?.label ??
              ""
            }
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Box component="li" {...props}>
                <span style={{ width: "32px" }}>
                  {getSymbolFromCurrency(option.code) || option.code}
                </span>

                <span style={{ marginLeft: "12px" }}>
                  {option.label} ({option.code})
                </span>
              </Box>
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            renderInput={(params) => (
              <>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  error={Boolean(errors[name])}
                  helperText={helperText}
                  InputLabelProps={{ children: undefined }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 700,
                          opacity: 0.7,
                          textAlign: "center",
                          width: "32px",
                        }}
                      >
                        {getCurrencySymbol(field.value)}
                      </Typography>
                    ),
                  }}
                  size="small"
                />
                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) => (
                    <FormHelperText error>{message}</FormHelperText>
                  )}
                />
              </>
            )}
            onChange={(_event, data) => field.onChange(data?.code ?? "")}
          />
        )}
      />
    </InputContainerDiv>
  )
}

export default CurrenciesSelect
