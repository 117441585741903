import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from "react"

import CloseIcon from "@mui/icons-material/Close"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Snackbar from "@mui/material/Snackbar"

type SnackbarSeverity = "error" | "warning" | "info" | "success"

export interface SnackbarOptions {
  severity?: SnackbarSeverity
  title?: string
}

interface SnackbarContextType {
  openSnackbar: (message: string, options?: SnackbarOptions) => void
  closeSnackbar: () => void
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TransitionSlide = (props: any) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction="left" />
}

interface SnackbarProviderProps {
  children: ReactNode
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    SnackbarSeverity | undefined
  >("info")
  const [snackbarTitle, setSnackbarTitle] = useState<string | undefined>("")

  const snackbarContextValue = useMemo(() => {
    const openSnackbar = (message: string, options?: SnackbarOptions) => {
      // remove "ApolloError:" from the message
      const cleanedMessage = message.replace("ApolloError:", "")
      setSnackbarMessage(cleanedMessage)

      if (options) {
        setSnackbarSeverity(options?.severity)
        setSnackbarTitle(options?.title)
      }

      setSnackbarOpen(true)
    }

    const closeSnackbar = () => {
      setSnackbarOpen(false)
      setSnackbarMessage("")
      setSnackbarSeverity("info")
      setSnackbarTitle(undefined)
    }

    return { openSnackbar, closeSnackbar }
  }, []) // Pass an empty array as dependencies to useMemo for one-time creation

  return (
    <SnackbarContext.Provider value={snackbarContextValue}>
      {children}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={snackbarContextValue.closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={TransitionSlide}
        // fixes bug where empty div stays in DOM after closing snackbar
        sx={snackbarOpen ? { right: "24px" } : { right: "-500px !important" }}
      >
        <Alert
          onClose={snackbarContextValue.closeSnackbar}
          severity={snackbarSeverity}
          action={
            <IconButton
              size="small"
              onClick={snackbarContextValue.closeSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {snackbarTitle && <AlertTitle>{snackbarTitle}</AlertTitle>}
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider")
  }
  return context
}

export default SnackbarProvider
