import React from "react"

import { OrganizationSwitcher } from "@clerk/clerk-react"
import { dark } from "@clerk/themes"
import Box from "@mui/material/Box"

import ErrorBoundary from "@components/ErrorBoundary"
import { useThemeContext } from "@providers/AppThemeProvider"

import routes from "@constants/routes"

const OrgSwitcher: React.FC = () => {
  const { darkMode } = useThemeContext()

  return (
    <ErrorBoundary>
      <Box sx={{ px: 2, mt: -1 }}>
        <OrganizationSwitcher
          afterSelectOrganizationUrl={routes.DASHBOARD}
          appearance={{
            baseTheme: darkMode ? dark : undefined,
            elements: {
              organizationSwitcherTrigger: {
                maxWidth: "200px", // to fit into the sidebar even when the org name is long
              },
              organizationSwitcherPopoverActionButton: {
                display: "none",
              },
              "cl-organizationSwitcherTrigger:hover": {
                backgroundColor: "transparent",
              },
              organizationSwitcherPopoverActions: {
                "> div": {
                  maxHeight: "50vh",
                },
              },
              organizationSwitcherPopoverActionButton__createOrganization: {
                visibility: "hidden",
                "max-height": 0,
                "min-height": "unset",
                padding: "unset",
              },
              avatarBox: {
                height: "24px",
                width: "24px",
              },
              avatarImage: { maxHeight: "24px", maxWidth: "24px" },
            },
          }}
          hidePersonal
        />
      </Box>
    </ErrorBoundary>
  )
}

export default OrgSwitcher
